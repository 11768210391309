import React from 'react'
import { useState, useEffect ,useRef} from "react"
//import DiscController2 from "./components/Zone2";
import DiscController1 from "./components/zone1";
//import DiscController3 from "./components/zone3";
//import DiscController4 from "./components/zone4";
import time from "./components/Updatetime";


async function Mount() {

  const tsk = window.__RUNTIME_CONFIG__.REACT_TSK
  var requestOptions = {
    method: 'GET',
    // mode: "no-cors",
    mode: 'cors',
    headers: {
      // 'Access-Control-Allow-Origin':'*'
      'ebobo':tsk
    },
    redirect: 'follow'
  };
  //from url string ?
  const queryParameters = new URLSearchParams(window.location.search)
  const payloadUrl = queryParameters.get("payload")
  const payloadId = queryParameters.get("id")
  // const { REACT_APP_API_URL } = process.env
  let url = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

  if (payloadUrl != undefined){
    url = payloadUrl
    //console.log("--->Taking over by Url:",url);
  }else if(payloadId != undefined){
    // const pieces = url.split(/[\s,]+/)
    const pieces = url.split('/')
    const currentId = pieces[pieces.length - 1]
    //console.log("--->Taking over by Id:",url,",current Id:",currentId);
    url = url.replace(currentId,payloadId)
    //console.log("--->Taking over by Id:",url);
  }
  //console.log("Calling it ok:",url);
  let response= {};
  let fileJSON={};
  try{
    response = await fetch(url, requestOptions);  // waits until the request completes...
    fileJSON = await response.json();
    //console.log("Calling Done:",url);
    var decodedCanvas = Buffer.from(fileJSON.canvas, 'base64');
    var jsonstring = decodedCanvas.toString()
    fileJSON = JSON.parse(jsonstring)
    return fileJSON
  }catch( error)
  {
    console.error("Fetch error:", error)
  }

  return {}

} // end mount

function Connection() {
  const [fileJSON, setFileJSON] = useState(null);
  const prevFileJSON = useRef(null);

  // Fonction pour effectuer une requête
  const fetchData = async () => {
    try {
      const result = await Mount();

      // Comparaison avec le JSON précédent
      if (JSON.stringify(result) !== JSON.stringify(prevFileJSON.current)) {
        //console.log("Le nouveau JSON est différent de l'ancien JSON");
        //console.log("Ancien JSON :", prevFileJSON.current);
        //console.log("Nouveau JSON :", result);

        // Mettre à jour l'état avec le nouveau JSON
        setFileJSON(result);

        // Mettre à jour la référence du JSON précédent
        prevFileJSON.current = result;
      } else {
        //console.log("Le nouveau JSON est identique à l'ancien JSON");
        return;
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // Exécuter fetchData au montage du composant
  useEffect(() => {
    fetchData();

    // Exécuter fetchData périodiquement (toutes les time secondes)
    const interval = setInterval(fetchData, time);

    // Nettoyer l'intervalle lors du démontage du composant
    return () => clearInterval(interval);
  }, []); // Le tableau vide [] signifie que cela ne dépend d'aucune variable, donc cela ne s'exécutera qu'au montage

  /*return (fileJSON !== null ? (
    <div className=" vh-100 py-1 container-fluid ">
      <div className="  h-100 row px-1 ">
        <DiscController1 file1={fileJSON} />
      </div>
      <div style={{display: 'none'}} className=" row  h-25 px-1 pt-1" >
        <DiscController2 file2={fileJSON} />
        <DiscController3 file3={fileJSON} />
        <DiscController4 file4={fileJSON} />
      </div>
    </div >
  ) : (""))*/

  return (fileJSON !== null ? (
    <div className=" vh-100 py-1 container-fluid ">
      <div className="  h-100 row px-1 ">
      <DiscController1 file1={fileJSON} />
      </div>
    </div >
  ) : (""))

}// end connection


function App() {

  return <Connection/>
}

export default App;
