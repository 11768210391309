import React from 'react'
//import Carousel from 'react-bootstrap/Carousel';
//import FirstAreaStyle from './FirstAreaStyle';
//import URL from './Defaultimage';
import { useState, useEffect } from "react";
//import Style from './Style';




//set disc playback end time
function DiscController1(props) {

    // current date
    var now = Math.round(Date.now() / 1000)

    // filtrer la zone 1 
    var data = props.file1.slots.filter(payload => {
        return (payload.zone === Number("1") ? payload : null)
    });

    var i = 0
    var index_debut = -1

    //trier par plus petit start_timestamp
    //data.sort((a, b) => (a.start_timestamp > b.start_timestamp ? 1 : -1))

    var d = new Date()
    var seconde = d.getSeconds()
    //console.log("seconde of date", seconde)

    while (true) {
        if (data[i].start_timestamp >= (now - (seconde + 3))) {
            index_debut = i
            break
        }
        i++
    }
    if (index_debut === -1) {
        return;
    }
    //console.log("index debut", index_debut)
    var new_data1 = JSON.parse(JSON.stringify(data));  // Créer une copie profonde de data
    new_data1 = new_data1.slice(index_debut, (new_data1.length + 1))

    var temps_debut_execution_app = now
    if (new_data1[0].start_timestamp + 1 <= temps_debut_execution_app) {  // modification du premier slot pour rattraper le temps perdu entre le temps de debut de 
        new_data1[0].start_timestamp = temps_debut_execution_app          // l'execution de l'application et le start_time du premier slot 
    }


    return <SlotDisplay1 slots={new_data1} />

} // end DiscController1 

function SlotDisplay1(props) {
    const { slots } = props;
    const [currentSlotIndex, setCurrentSlotIndex] = useState(0);

    useEffect(() => {
        //console.log("Effect is running");
        const interval = setInterval(() => {
            //console.log("Interval function is running");
            const now = Math.round(Date.now() / 1000);
            //console.log("Current time:", now);
            for (let i = 0; i < slots.length; i++) {
                //console.log("Checking slot:", slots[i]);
                if (slots[i].start_timestamp <= now && slots[i].end_timestamp >= now) {
                    //console.log("Slot found:", slots[i]);
                    //console.log("Index:", i);
                    setCurrentSlotIndex(i);
                    break;
                }
            }
        }, 1000); // Check every second

        return () => {
            //console.log("Clearing interval");
            clearInterval(interval);
        };
    }, [slots]);

    return (
        <div className="col mx-1 px-0 h-100 w-100">
            {slots.map((slot, index) => (
                index === currentSlotIndex && (
                    <div key={slot.id} className="text-center h-100 w-100 "  >
                        <img src={slot.media_src} alt={`Slot ${index}`} className="h-100 w-100 img-rounded"/>
                    </div>
                )
            ))}
        </div>
    );
}



export default DiscController1

